<template>
  <div>

    <b-card title="AdeConnect 🔒" />
    <div>
      <b-container class="bv-example-row">
        <!-- ComboBox -->
        <b-row>
          <b-col
            class="mb-1"
            cols="4"
          >
            <b-form-group
              description="Selecione o CNPJ."
            >
              <combo-box-client ref="cboxclient" />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group>
              <b-button
                variant="primary"
                type="button"
                @click="safxTable()"
              >
                Carregar
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- END ComboBox -->
        <b-row>
          <b-col>
            <polar-area-chart />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <bar-chart />
          </b-col>
          <b-col>
            <horizontal-bar-chart />
          </b-col>
        </b-row>
      </b-container>

    </div>

    <validate-logon-keycloak ref="validatelogon" />
  </div>
</template>

<script>
import {
  BCard, BRow, BContainer, BCol, BButton, BFormGroup,
} from 'bootstrap-vue'
import BarChart from '@/views/home/BarChart.vue'
import PolarAreaChart from '@/views/home/PolarAreaChart.vue'
import HorizontalBarChart from '@/views/home/HorizontalBarChart.vue'
import ComboBoxClient from '@/views/reusable-components/ComboBoxClient.vue'
import ValidateLogonKeycloak from '@/views/reusable-components/ValidateLogonKeycloak.vue'

export default {
  name: 'Home',
  components: {
    BCard,
    BCol,
    BRow,
    BContainer,
    ValidateLogonKeycloak,
    BarChart,
    PolarAreaChart,
    HorizontalBarChart,
    ComboBoxClient,
    BButton,
    BFormGroup,
  },
  data() {
    return {

      rolesNeeded: ['everyone'],
      options: [
        { value: null, text: 'Carregando' },
      ],
      selectClient: {
        id: 'select-client',
      },
    }
  },
  mounted() { // Mounted has to be used when using a Child and trigger after the import
    this.validateUserLogon()
    this.setUser()
  },
  methods: {
    validateUserLogon() {
      this.$refs.validatelogon.validateLogon()
    },
    setUser() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      this.$parent.$parent.$parent.$refs.adenav.username = userData.fullName
    },
  },
}
</script>
