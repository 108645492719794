<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Status dos Processamentos</b-card-title>
      <!-- datepicker -->
      <!-- <div class="d-flex align-items-center">
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range'}"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
        />
      </div> -->
      <!-- datepicker -->
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <chartjs-component-bar-chart
        :height="400"
        :data="chartjsData.latestBarChart.data"
        :options="chartjsData.latestBarChart.options"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import axios from 'axios'
import {
  BCard, BCardHeader, BCardBody, BCardTitle,
} from 'bootstrap-vue'
// import flatPickr from 'vue-flatpickr-component'
import ChartjsComponentBarChart from '../charts-components/ChartjsComponentBarChart.vue'
import chartjsData from '../chartjsData'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    // flatPickr,
    ChartjsComponentBarChart,
  },
  data() {
    return {
      chartjsData,
      labels: [],
      datasets: [],
      // rangePicker: ['2022-05-01', '2022-05-10'],
    }
  },
  methods: {
    async createChart() {
      const chartColors = {
        redColor: '#f61010',
        primaryColorShade: '#836AF9',
        yellowColor: '#ffe800',
        successColorShade: '#28dac6',
        warningColorShade: '#ffe802',
        warningLightColor: '#FDAC34',
        infoColorShade: '#299AFF',
        greyColor: '#4F5D70',
        blueColor: '#2c9aff',
        blueLightColor: '#84D0FF',
        greyLightColor: '#EDF1F4',
        tooltipShadow: 'rgba(0, 0, 0, 0.25)',
        lineChartPrimary: '#666ee8',
        lineChartDanger: '#ff4961',
        labelColor: '#6e6b7b',
        grid_line_color: 'rgba(200, 200, 200, 0.2)',
      }
      this.show = true // loading page b-overlay
      this.isBusy = true
      let selectedCnpj = ''
      try {
        selectedCnpj = this.$parent.$refs.cboxclient.selected
        if (selectedCnpj === null) {
          throw Error('Selecione um cliente!')
        }
        const res = await axios.get(`/airflow/getInterfaces/dashboard/${selectedCnpj}`)
        const { data } = res
        this.labels = data.labels
        this.datasets = [
          {
            label: 'Com sucesso',
            detail: data.successData.map(datapoint => ({
              loc: [datapoint.label, datapoint.value],
              msg: 'Success',
              type: 'Success',
            })),
            backgroundColor: chartColors.successColorShade,
            borderColor: 'transparent',
          },
          {
            label: 'Com erro',
            detail: data.errorData.map(datapoint => ({
              loc: [datapoint.label, datapoint.value],
              msg: 'Error',
              type: 'Error',
            })),
            backgroundColor: chartColors.redColor,
            borderColor: 'transparent',
          },
        ]

        // Create the chart using the labels and datasets data
        this.chartjsData = {
          labels: this.labels,
          datasets: this.datasets,
        }
      } catch (error) {
        console.log(error)
      }
      this.isBusy = false
      this.show = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
